@if (loading) {
  <dpa-timeout-loader [skeletonClassName]="chartTypeClassName" />
} @else {
  @if (isDataPresent()) {
    @if (activeChartType === CHART_TYPE.BUBBLE) {
      <dpa-bubble-chart
        [ngxChart]="ngxChart"
        [showDetailsLink]="showDetailsLink"
        [isPercentage]="isPercentage"
        [stackedTooltip]="stackedTooltip || defaultStackedToolTip"
        [seriesTooltip]="seriesTooltip"
        [yAxisLabel]="yAxisLabel"
        [activeSeries]="activeSeries"
        (onViewDetails)="onDrilldown($event)"
      />
    }
    @if (activeChartType === CHART_TYPE.LINE) {
      <dpa-unstacked-line-chart
        [ngxChart]="ngxChart"
        [overlayChart]="overlayChart"
        [showDetailsLink]="showDetailsLink"
        [isPercentage]="isPercentage"
        [isRanged]="isRangedData"
        [stackedTooltip]="stackedTooltip || defaultStackedToolTip"
        [seriesTooltip]="seriesTooltip"
        [yAxisLabel]="yAxisLabel"
        [activeSeries]="activeSeries"
        [showXAxis]="showXAxis"
        [showYAxis]="showYAxis && !isInvertMode"
        [rotateXAxisTicks]="rotateXAxisTicks"
        [referenceLines]="referenceLines"
        [showRefLines]="showRefLines"
        [showRefLabels]="showRefLabels"
        [showTimeline]="showTimeline"
        [yAxisTickFormatting]="yAxisTickFormatting"
        [yAxisTicks]="yAxisTicks"
        [markers]="markers"
        [markerTooltipTemplate]="markerTooltipTemplate"
        [yScaleMax]="yScaleMax"
        [yScaleMin]="yScaleMin"
        [trimXAxisTicks]="trimXAxisTicks"
        [focussedSequence]="focussedSequence"
        [overlayTooltipTemplate]="overlayTooltip"
        [showMaxDomain]="showMaxDomain"
        [roundDomains]="roundDomains"
        [forceGradient]="forceGradient"
        [useHardStops]="useHardStops"
        [customGradientStops]="customGradientStops"
        [colorMappingFn]="colorMappingFn"
        [doNotRenderChart]="doNotRenderChart"
        [displayTimelineOverXAxis]="displayTimelineOverXAxis"
        (domainChange)="domainChange.emit($event)"
        (onViewDetails)="onDrilldown($event)"
        (onMarkerClick)="handleMarkerClick($event)"
      />
    }
    @if (activeChartType === CHART_TYPE.LINE_SPARK) {
      <dpa-unstacked-line-chart
        [ngxChart]="ngxChart"
        [showDetailsLink]="showDetailsLink"
        [isPercentage]="isPercentage"
        [stackedTooltip]="stackedTooltip || defaultStackedToolTip"
        [seriesTooltip]="seriesTooltip"
        [yAxisLabel]="yAxisLabel"
        [activeSeries]="activeSeries"
        [isSpark]="true"
        [showYAxis]="!isInvertMode"
        [rotateXAxisTicks]="rotateXAxisTicks || ngxChart?.trendMode === TREND_MODE[TREND_MODE.SNAPSHOT]"
        [referenceLines]="referenceLines"
        [showRefLines]="showRefLines"
        [showRefLabels]="showRefLabels"
        [showTimeline]="showTimeline"
        [markers]="markers"
        [markerTooltipTemplate]="markerTooltipTemplate"
        (onViewDetails)="onDrilldown($event)"
      />
    }
    @if (activeChartType === CHART_TYPE.AREA) {
      <dpa-line-chart
        [ngxChart]="ngxChart"
        [showDetailsLink]="showDetailsLink"
        [isPercentage]="isPercentage"
        [stackedTooltip]="stackedTooltip || defaultStackedToolTip"
        [seriesTooltip]="seriesTooltip"
        [yAxisLabel]="yAxisLabel"
        [activeSeries]="activeSeries"
        [rotateXAxisTicks]="rotateXAxisTicks"
        [showXAxis]="showXAxis"
        [showYAxis]="showYAxis"
        [showTimeline]="showTimeline"
        [chartCurveType]="chartCurveType"
        [markers]="markers"
        [markerTooltipTemplate]="markerTooltipTemplate"
        (onViewDetails)="onDrilldown($event)"
      />
    }
    @if (activeChartType === CHART_TYPE.VERTICAL || activeChartType === CHART_TYPE.VERTICAL_GROUP) {
      <dpa-vertical-chart
        [ngxChart]="ngxChart"
        [showDetailsLink]="showDetailsLink"
        [isPercentage]="isPercentage"
        [stackedTooltip]="stackedTooltip || defaultStackedToolTip"
        [seriesTooltip]="seriesTooltip"
        [yAxisLabel]="yAxisLabel"
        [overlayChart]="overlayChart"
        [activeSeries]="activeSeries"
        [showXAxis]="showXAxis"
        [showYAxis]="showYAxis"
        [showXAxisLabel]="showXAxisLabel"
        [rotateXAxisTicks]="rotateXAxisTicks || ngxChart?.trendMode === TREND_MODE[TREND_MODE.SNAPSHOT]"
        [showDataLabel]="showDataLabel"
        [yScaleMax]="yScaleMax"
        [focussedSequence]="focussedSequence"
        [chartType]="activeChartType"
        [overlayTooltipTemplate]="overlayTooltip"
        [yAxisRightLabel]="yAxisRightLabel"
        (onViewDetails)="onDrilldown($event)"
      />
    }
    @if (activeChartType === CHART_TYPE.HORIZONTAL || activeChartType === CHART_TYPE.HORIZONTAL_GROUP) {
      <dpa-horizontal-chart
        [ngxChart]="ngxChart"
        [showDetailsLink]="showDetailsLink"
        [isPercentage]="isPercentage"
        [stackedTooltip]="stackedTooltip || defaultStackedToolTip"
        [activeSeries]="activeSeries"
        [showXAxis]="showXAxis"
        [showYAxis]="showYAxis"
        [showYAxisLabel]="showYAxisLabel"
        [showDataLabel]="showDataLabel"
        [xAxisLabel]="xAxisLabel"
        [chartType]="activeChartType"
        (onViewDetails)="onDrilldown($event)"
      />
    }
    @if (activeChartType === CHART_TYPE.DONUT) {
      <dpa-donut-chart
        [ngxChart]="ngxChart"
        [showDetailsLink]="showDetailsLink"
        [stackedTooltip]="stackedTooltip || percentStackedToolTip"
        [activeSeries]="activeSeries"
        [showAllAvailableLabels]="showAllAvailableLabels"
        [showLabels]="showLabels"
        [totalHeaderKey]="totalHeaderKey"
        [totalHeaderValue]="totalHeaderValue"
        [totalHeaderClass]="totalHeaderClass"
        (onViewDetails)="onDrilldown($event)"
      />
    }
    @if (activeChartType === CHART_TYPE.METRIC) {
      <dpa-metric-chart [ngxChart]="ngxChart" />
    }
    @if (activeChartType === CHART_TYPE.TABLE) {
      <dpa-table-chart
        [ngxChart]="ngxChart"
        [tableColumnNames]="tableColumnNames"
        [tableColumnLabelsByName]="tableColumnLabelsByName"
        [tableCellTemplatesByName]="tableCellTemplatesByName"
        [showDetailView]="showDetailView"
        [detailHeaderTemplate]="detailHeaderTemplate"
        [detailBodyTemplate]="detailBodyTemplate"
        [selectable]="selectable"
        [enablePagination]="enablePagination"
        [tableSettings]="tableSettings"
        [expandedRowTemplate]="tableExpandedRowTemplate"
        [mergeColumns]="mergeColumns"
        [showTableFilter]="showTableFilter"
        [isCountersClickable]="isCountersClickable"
        [columnWidthByKey]="columnWidthByKey"
        (selectedChange)="onSelectedChange($event)"
        (onViewDetails)="onDrilldown($event)"
      />
    }
    @if (activeChartType === CHART_TYPE.HEAT_MAP) {
      <dpa-heat-map-chart
        [ngxChart]="ngxChart"
        [showDetailsLink]="showDetailsLink"
        [stackedTooltip]="stackedTooltip || defaultStackedToolTip"
        [seriesTooltip]="seriesTooltip"
        [yAxisLabel]="yAxisLabel"
        [activeSeries]="activeSeries"
        [showXAxis]="showXAxis"
        [showYAxis]="showYAxis"
        [showLegend]="showLegend"
        [colorMappingFn]="colorMappingFn"
        [hideCellByValueMappingFn]="hideCellByValueMappingFn"
        [renderCellAsCircle]="renderCellAsCircle"
        [innerPadding]="innerPadding"
        [bandwidth]="bandwidth"
        [colorScheme]="colorScheme"
        [alignWithTimeline]="alignHeatMapWithTimeline"
        (onViewDetails)="onDrilldown($event)"
      />
    }
    @if (activeChartType === CHART_TYPE.TREE_MAP) {
      <dpa-tree-map-chart
        [ngxChart]="ngxChart"
        [showDetailsLink]="showDetailsLink"
        [stackedTooltip]="stackedTooltip || defaultStackedToolTip"
        [labelFormatting]="labelFormatting"
        [valueFormatting]="valueFormatting"
        [showSameSizeCell]="showSameSizeCell"
        (onViewDetails)="onDrilldown($event)"
      />
    }
  } @else {
    <div class="no-data-container">
      <dpa-no-search-results
        [message]="noDataMessage || 'COMMON_MESSAGES.NO_DATA_AVAILABLE' | translate"
        [tooltipText]="noDataTooltip"
      />
    </div>
  }
}

@if (!loading) {
  @if (isLegendVisible) {
    <dpa-legend
      [ngxChart]="ngxChart"
      [focusedSeries]="focusedSeries"
      [activeSeries]="activeSeries"
      [isExploreMode]="isExploreMode"
      [availableColumns]="availableColumns"
      [colorizedColumn]="colorizedColumn"
      (clearFocusedSeries)="clearFocusedSeries()"
      (selectedColumnChange)="onSelectColorizedColumn($event)"
      (selectSeries)="onSelectSeries($event)"
      (activateSeries)="onActivateSeries($event)"
      (deactivateSeries)="onDeactivateSeries($event)"
    />
  } @else {
    @if (overlayChart && showLegend) {
      <dpa-legend-item
        [selected]="true"
        [color]="ngxChart?.noBucketingColor"
        [name]="yAxisLabel ?? ngxChart?.labels?.counter"
        class="overlay-legend"
      />
    }
  }
  <ng-template #noBucketingColor>
    @if (overlayChart && showLegend) {
      <dpa-legend-item
        [selected]="true"
        [color]="ngxChart?.noBucketingColor"
        [name]="ngxChart?.labels?.counter"
        class="overlay-legend"
      />
    }
  </ng-template>
  @if (overlayChart && showLegend) {
    @if (showOverlayLegendTitle) {
      <div class="overlay-legend-title bold pl-x1">{{ 'WIDGET_CUSTOMIZE.OVERLAY_LEGEND' | translate }}</div>
    }
    @if (isOverlayLegendVisible) {
      <dpa-legend
        [ngxChart]="overlayChart"
        [isExploreMode]="false"
      />
    } @else {
      <dpa-legend-item
        [selected]="true"
        [color]="overlayChart?.noBucketingColor"
        [name]="yAxisRightLabel ?? overlayChart?.labels?.counter"
        class="overlay-legend"
      />
    }
  }
}
<ng-template
  #defaultStackedToolTip
  let-ngxModel="ngxModel"
  let-tooltip="tooltip"
>
  <div class="tool-tip-container">
    @if (tooltip.rollingDateBucket) {
      <div>
        {{
          'COMMON_MESSAGES.START_TO_END_TIME'
            | translate: { start: tooltip.rollingDateBucket.startDateStr, end: tooltip.rollingDateBucket.endDateStr }
        }}
      </div>
    }
    @for (filter of tooltip?.filters; track filter?.keyLabel) {
      <div class="key-value-pair">
        <div class="key-label">
          <span>{{ filter.keyLabel }}</span>
          <cds-icon
            class="filter-arrow ml-x1"
            size="18"
            shape="arrow"
            direction="right"
          />
        </div>
        <div class="value-label pl-x2">
          {{ filter.value }}
        </div>
      </div>
    }
    @if (tooltip.tableGroupedByLabel) {
      <div class="key-value-pair">
        <div class="key-label">{{ 'WIDGET_CUSTOMIZE.GROUPED_BY' | translate }}:</div>
        <div class="value-label pl-x2">
          {{ tooltip.tableGroupedByLabel }}
        </div>
      </div>
    }
    <div class="sub-group-container">
      @for (keyValuePair of tooltip.tableData | slice: 0 : maxTooltipItems; track keyValuePair) {
        <div class="key-value-pair">
          <span class="key-label">{{ $any(keyValuePair).keyLabel }}:</span>
          <span class="value-label pl-x2">
            {{ $any(keyValuePair).value }}
          </span>
        </div>
      }
      @if (tooltipItemsOverMax(tooltip.tableData); as remainingItems) {
        <div>
          <em>{{ 'WIDGET_CUSTOMIZE.CLICK_TO_VIEW_N_MORE' | translate: { n: remainingItems } }}</em>
        </div>
      }
    </div>

    <div class="key-value-pair">
      <span class="key-label">{{ 'COMMON_MESSAGES.TOTAL' | translate }}:</span>
      @if (isRangedData) {
        <span class="value-label pl-x2">
          {{ ngxChart.labels?.counterFormatter(tooltip.counterValue) }}
        </span>
      }
      @if (!isRangedData) {
        <span class="value-label pl-x2">
          {{ tooltip.counterFormattedValue }}
        </span>
      }
    </div>
    @if (isRangedData) {
      <div class="key-value-pair">
        <span class="key-label">{{ 'COMMON_MESSAGES.RANGE' | translate }}:</span>
        <span class="value-label pl-x2">
          {{ tooltip.counterFormattedValue }}
        </span>
      </div>
    }
  </div>
</ng-template>

<ng-template
  #seriesTooltip
  let-tooltipItems="tooltipItems"
  let-usingFakeGroupBy="usingFakeGroupBy"
>
  <div class="series-tooltip-container">
    @if (tooltipItems && tooltipItems[0]?.name) {
      <div>
        {{ tooltipItems[0].name }}
      </div>
    }
    @for (tooltipItem of sortFilterTooltipItems(tooltipItems) | slice: 0 : maxTooltipItems; track tooltipItem) {
      <div class="tooltip-item">
        @if (showSeriesNames && !usingFakeGroupBy) {
          <div class="series-label">
            <span
              [style.background-color]="$any(tooltipItem).color"
              class="tooltip-item-color"
            ></span>
            <span>{{ $any(tooltipItem).series }}:</span>
          </div>
        }
        <span class="value-label pl-x2">
          @if (isRangedData) {
            <span>{{ 'COMMON_MESSAGES.TOTAL' | translate }}:</span>
          }
          {{ ngxChart.labels?.counterFormatter($any(tooltipItem).value) }}
          @if (isRangedData) {
            <div>
              <ng-container>
                {{ 'COMMON_MESSAGES.RANGE' | translate }}:
                {{ ngxChart.labels?.counterFormatter($any(tooltipItem).min) }}
                -
                {{ ngxChart.labels?.counterFormatter($any(tooltipItem).max) }}
              </ng-container>
            </div>
          }
        </span>
      </div>
    }
    @if (tooltipItemsOverMax(tooltipItems); as remainingItems) {
      <div>
        {{ 'COMMON_MESSAGES.N_MORE_DOT_DOT' | translate: { n: remainingItems } }}
      </div>
    }
    @if (tooltipItems?.length > 1 && !hideSeriesTooltipSubtotal) {
      <div class="key-value-pair">
        <span class="key-label">
          {{ 'COMMON_MESSAGES.SUBTOTAL' | translate }}
        </span>
        <span class="value-label pl-x2">
          {{ ngxChart.labels?.totalCountFormatter(tooltipItems) }}
        </span>
      </div>
    }
  </div>
</ng-template>

<ng-template
  #percentStackedToolTip
  let-ngxModel="ngxModel"
  let-tooltip="tooltip"
  let-total="total"
>
  <div class="tool-tip-container">
    @if (tooltip.rollingDateBucket) {
      <div>
        {{
          'COMMON_MESSAGES.START_TO_END_TIME'
            | translate: { start: tooltip.rollingDateBucket.startDateStr, end: tooltip.rollingDateBucket.endDateStr }
        }}
      </div>
    }
    @for (filter of tooltip.filters; track filter) {
      <div class="key-value-pair">
        <div class="key-label">
          <span>{{ filter.keyLabel }}</span>
          <cds-icon
            class="filter-arrow ml-x1"
            size="18"
            shape="arrow"
            direction="right"
          />
        </div>
        <div class="value-label pl-x2">
          {{ filter.value }}
          <span>({{ tooltip.counterValue / total | percent: '1.0' }})</span>
        </div>
      </div>
    }
    @if (tooltip.tableGroupedByLabel) {
      <div class="key-value-pair">
        <div class="key-label">{{ 'WIDGET_CUSTOMIZE.GROUPED_BY' | translate }}:</div>
        <div class="value-label pl-x2">
          {{ tooltip.tableGroupedByLabel }}
        </div>
      </div>
    }
    <div class="sub-group-container">
      @for (keyValuePair of tooltip.tableData | slice: 0 : maxTooltipItems; track keyValuePair) {
        <div class="key-value-pair">
          <span class="key-label">{{ $any(keyValuePair).keyLabel }}:</span>
          <span class="value-label pl-x2">
            {{ $any(keyValuePair).value }}
          </span>
        </div>
      }
      @if (tooltipItemsOverMax(tooltip.tableData); as remainingItems) {
        <div>
          <em>{{ 'WIDGET_CUSTOMIZE.CLICK_TO_VIEW_N_MORE' | translate: { n: remainingItems } }}</em>
        </div>
      }
    </div>

    <div class="key-value-pair">
      <span class="key-label">
        {{ 'COMMON_MESSAGES.SUBTOTAL' | translate }}
      </span>
      <span class="value-label pl-x2">
        {{
          'COMMON_MESSAGES.SUB_TOTAL_OF_GRAND_TOTAL' | translate: { subTotal: tooltip.counterValue | number, grandTotal: total | number }
        }}
      </span>
    </div>
  </div>
</ng-template>

<ng-template
  #overlayTooltip
  let-tooltipItems="tooltipItems"
  let-seriesName="seriesName"
>
  <div class="series-tooltip-container overlay-tooltip-container">
    @if (seriesName) {
      <div>{{ 'COMMON_MESSAGES.DATE' | translate }} &rightarrow; {{ seriesName }}</div>
    }
    @for (tooltipItem of tooltipItems; track tooltipItem?.name) {
      <div class="overlay-tooltip">
        @if (tooltipItem?.series?.length === 1) {
          <div class="tooltip-item">
            <ng-container *ngLet="tooltipItem?.series[0] as seriesItem">
              <div class="series-label">
                <span
                  [style.background-color]="seriesItem.color"
                  class="tooltip-item-color"
                ></span>
                <span class="main-label">{{ tooltipItem.name }}:</span>
              </div>
              <span class="value-label pl-x2">
                {{ seriesItem.value }}
              </span>
            </ng-container>
          </div>
        } @else {
          <div class="main-label">{{ tooltipItem.name }}</div>
          <div class="tooltip-item">
            <div class="series-label">
              <span>{{ 'COMMON_MESSAGES.TOTAL' | translate }}:</span>
            </div>
            <span class="value-label pl-x2">
              {{ tooltipItem.total }}
            </span>
          </div>
          @for (seriesItem of tooltipItem.series | slice: 0 : maxTooltipItems; track $any(seriesItem)?.name) {
            <div class="tooltip-item">
              <div class="series-label">
                <span
                  [style.background-color]="$any(seriesItem).color"
                  class="tooltip-item-color"
                ></span>
                <span>{{ $any(seriesItem).name }}:</span>
              </div>
              <span class="value-label pl-x2">
                {{ $any(seriesItem).value }}
              </span>
            </div>
          }
        }
      </div>
    }
  </div>
</ng-template>
